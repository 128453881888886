import AbstractService from "./AbstractService";
import Axios from "axios";
import AttachmentService, { AttachmentPacket } from './AttachmentService';
import FileUploader from '@/utilities/FileUploader';

export default class CustomerPaymentAttachmentService extends AbstractService implements AttachmentService {
    private controller = "customer-payment-attachments";
    
    public makeUploader(file: File) {
        return new FileUploader(this, file);
    }

    public previewUrl(q: any = null) {
        return this.makeUrlWithQueries(`${this.controller}/preview`, q);
    }

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public post(customerPaymentAttachment: any) {
        const d = { customerPaymentAttachment };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public submit(customerPaymentId: number, attachmentIds: number[]) {
        const d = { customerPaymentId, attachmentIds };
        const url = this.makeUrl(`${this.controller}/submit`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public upload<T>(packet: AttachmentPacket) {
        const url = this.makeUrl(`${this.controller}/upload`);
        var config = this.makeConfig();
        config.headers['Attachment-Id'] = String(packet.id);
        config.headers['Attachment-Name'] = this.toHex(packet.name);
        config.headers['Attachment-Hash'] = packet.hash;
        config.headers['Content-Type'] = packet.type;
        config.headers['Content-Range'] = `bytes ${packet.start}-${packet.end}/${packet.size}`;
        return Axios.put<T>(url, packet.chunk, config);
    }

    public delete(customerPaymentAttachment: any) {
        const d = { customerPaymentAttachment };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }

    private toHex(s: string) {
        var b = new TextEncoder().encode(s), r = "";
        b.forEach(e => r += ('00' + e.toString(16)).slice(-2));
        return r;
    }
}