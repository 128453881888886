

































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { MobileConfirmedOrderProperties } from "./MobileConfirmedOrderAView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import TransferRequestService from "@/services/TransferRequestService";

class MobileTransferDialogProperties {
    visible: boolean = false;
}

export { MobileTransferDialogProperties };

@Component({
    data: () => ({
        transferToken: "",
        saving: false,
    }),
})
export default class MobileTransferDialogView extends Vue {
    @Prop({ default: new MobileTransferDialogProperties() })
    private properties: MobileTransferDialogProperties;
    @Prop() private confirmedOrder: MobileConfirmedOrderProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private transferRequestService = new TransferRequestService();

    public get selected() {
        return this.confirmedOrder.customerOrders.filter((e) => e.selected).length;
    }

    public set selected(v: any) {}

    public async transfer() {
        try {
            const transferToken = this.$data.transferToken;
            const customerOrderIds = this.confirmedOrder.customerOrders
                .filter((e) => e.selected).map((e) => <number>e.id);

            const r = await this.transferRequestService.submit(transferToken, customerOrderIds);
            var m: any = this.$t("message.transfer-pending-approval", [r.data.uniqueNo]);
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.tag = { command: "transfer-submitted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public close() {
        this.properties.visible = false;
    }
}
