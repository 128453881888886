











































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";
import ProfileAddressService from "@/services/ProfileAddressService";

class MobileAddressDialogProperties {
    visible: boolean = false;
}

export { MobileAddressDialogProperties };

@Component({
    data: () => ({
        addresses: [],
        firstLoaded: false,
    }),
})
export default class MobileAddressDialogView extends Vue {
    @Prop({ default: new MobileAddressDialogProperties() })
    private properties: MobileAddressDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private profileAddressService = new ProfileAddressService();

    public get contentClass() {
        if (!this.$data.firstLoaded) {
            return "elevation-0 overflow-hidden";
        }
        return null;
    }

    public async created() {
        await this.load();
    }

    public async load() {
        try {
            const r = await this.profileAddressService.get({
                loadCity: true,
                loadState: true,
                loadCountry: true,
            });

            if (r.data.length > 0) {
                for (var i = 0; i < r.data.length; i++) {
                    r.data[i].full = AddressUtil.build(r.data[i]);
                }
                this.$data.addresses = r.data.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.$data.addresses = [];
                await this.$router.push("/m/profile#address-new");
            }
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.firstLoaded = true;
        }
    }

    public selected(address: any) {
        if (address) {
            this.$emit("selected", address);
        }
    }

    public close() {
        this.properties.visible = false;
    }
}
