import { AxiosRequestConfig } from 'axios';
import Jssha256 from "jssha/dist/sha256";
import AttachmentService, { AttachmentPacket } from "@/services/AttachmentService";

interface FileUploaderResponse<T> {
    request?: any;
    data: T;
    config: AxiosRequestConfig;
    status: number;
    statusText: string;
    headers: any;
    packet: AttachmentPacket;
    continue: () => Promise<FileUploaderResponse<T>>;
}

export { FileUploaderResponse };

export default class FileUploader {
    private service: AttachmentService;
    private file: File;
    private size: number = 1024 * 1024;

    constructor(service: AttachmentService, file: File) {
        this.service = service;
        this.file = file;
    }

    public async upload() {
        var sha256 = new Jssha256("SHA-256", "ARRAYBUFFER");
        return this.chunk(sha256, 0, 0);
    }

    private async chunk(sha256: Jssha256, id: number, start: number) {
        var chunkLast = false;

        const packet = <AttachmentPacket>{
            id,
            type: this.file.type,
            start,
            end: start + this.size,
            size: this.file.size
        };

        if (this.file.size < packet.end) {
            packet.end = this.file.size;
            chunkLast = true;
        }
        
        packet.chunk = this.file.slice(start, packet.end);        
        sha256.update(await packet.chunk.arrayBuffer()); 

        if (chunkLast) {
            packet.hash = sha256.getHash("HEX");
        }

        const r = <FileUploaderResponse<any>>await this.service.upload(packet);
        r.packet = packet;

        if (!chunkLast) {
            r.continue = () => {
                return this.chunk(sha256, r.data.id, start + this.size);                
            };
        }

        return r;
    }
}