























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { HomeProperties } from "@/components/home/HomeView.vue";
import { MobileMainProperties } from "./MobileMainView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { MobileDepositDialogProperties } from "./MobileDepositDialogView.vue";
import { MobileCheckoutDialogProperties } from "./MobileCheckoutDialogView.vue";
import { MobileTransferDialogProperties } from "./MobileTransferDialogView.vue";
import { MobileAddressDialogProperties } from "./MobileAddressDialogView.vue";
import EventManager from "@/utilities/EventManager";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";
import CompanyService from "@/services/CompanyService";
import SystemSettingService from "@/services/SystemSettingService";

const MobileWalletView = () => import("./MobileWalletView.vue");
const MobileConfirmedOrderBView = () => import("./MobileConfirmedOrderBView.vue");
const MobileConfirmedSummaryView = () => import("./MobileConfirmedSummaryView.vue");
const MobileDepositDialogView = () => import("./MobileDepositDialogView.vue");
const MobileCheckoutDialogView = () => import("./MobileCheckoutDialogView.vue");
const MobileTransferDialogView = () => import("./MobileTransferDialogView.vue");
const MobileAddressDialogView = () => import("./MobileAddressDialogView.vue");

class MobileConfirmedOrderProperties {
    selectAll: boolean = false;
    customerOrders: any[] = [];
    currency: any = {};
    shipping: any = null;
    deliveryMethod: string = "Standard-Delivery";
    allowedDeliveryMethods: string[] = [];
    voucherNumber: string = "";
    voucherNumberApplied: string = "";
    dateTimeFormat: string = null;
    events = new EventManager();
}

export { MobileConfirmedOrderProperties };

@Component({
    components: {
        MobileWalletView,
        MobileConfirmedOrderBView,
        MobileConfirmedSummaryView,
        MobileDepositDialogView,
        MobileCheckoutDialogView,
        MobileTransferDialogView,
        MobileAddressDialogView
    },
    computed: {
        screenSmall: function() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        screenMedium: function() {
            return this.$vuetify.breakpoint.mdAndDown;
        }
    }
})
export default class MobileConfirmedOrderAView extends Vue {
    @Prop() private home: HomeProperties;
    @Prop() private main: MobileMainProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private properties = new MobileConfirmedOrderProperties();
    private depositDialog = new MobileDepositDialogProperties();
    private checkoutDialog = new MobileCheckoutDialogProperties();
    private transferDialog = new MobileTransferDialogProperties();
    private addressDialog = new MobileAddressDialogProperties();
    private systemSettingService = new SystemSettingService();
    private companyService = new CompanyService();

    public created() {
        this.home.events.subscribe("alert-dialog-closed", this.alertDialogClosed);
        this.load();
    }

    public destroyed() {
        this.home.events.remove("alert-dialog-closed", this.alertDialogClosed);
    }

    public async loadCurrency() {
        const r = await this.companyService.currency();
        this.properties.currency = r.data;
    }

    public async loadSystemSetting() {
        const r = await this.systemSettingService.allowedDeliveryMethods();
        const allowed: string[] = r.data;
        this.properties.allowedDeliveryMethods = allowed ?? [];

        if (allowed.length > 0) {
            this.properties.deliveryMethod = allowed[0];
        }
    }

    public async load() {
        try {
            await this.loadSystemSetting();
            await this.loadCurrency();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async selectAllChanged() {
        this.properties.customerOrders.forEach((e) => (e.selected = this.properties.selectAll));
        await this.properties.events.fire("calculate-shipping-fee");
    }

    public async addressSelected(selected: any) {
        selected.address = AddressUtil.build(selected);
        this.properties.shipping = selected;
        this.addressDialog.visible = false;

        await this.properties.events.fire("calculate-shipping-fee");
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "deposit-submitted") {
                this.depositDialog.visible = false;
                this.properties.voucherNumber = "";
                this.properties.voucherNumberApplied = "";
                await this.properties.events.fire('load');
                await this.properties.events.fire('load-wallet');
            } else if (tag.command === "checkout-submitted") {
                this.checkoutDialog.visible = false;
                this.properties.voucherNumber = "";
                this.properties.voucherNumberApplied = "";
                await this.properties.events.fire('load');
                await this.properties.events.fire('load-wallet');
            } else if (tag.command === "transfer-submitted") {
                this.transferDialog.visible = false;
                await this.properties.events.fire('load');
            }
        }
    }
}
